<template>
  <div>
    <radio :show.sync="showStatus" @func="getMsgFormSon" />
    <auth :shows.sync="drawer" />
    <div class="header">
      <div class="content">
        <div style="display:flex;">
          <a href="./">
            <img src="@/assets/logo.png" alt="" class="web-logo">
          </a>
          <el-input v-model="searchVal" @keyup.enter.native="toSearch" class="search-input" suffix-icon="el-icon-zoom-in" placeholder="请输入搜索的数字藏品"></el-input>
        </div>
        <div class="navBar" style="display:flex;align-items:center">
          <div class="nav-item">
            <div @click="toShop()">藏品</div>
          </div>
          <div class="nav-item">
            <div @click="toType()">专辑</div>
          </div>
          <div class="nav-item">
            <div  @click="tonewsList">资讯</div>
          </div>
          <div class="nav-item">
            <div  @click="register">发布</div>
          </div>
          <div class="nav-item">
            <div @click="todrawer">认证</div>
          </div>
          <div class="nav-item">
            <div  @click="toHelp">帮助</div>
          </div>
          <div class="nav-item" v-if="!accessToken || accessToken == ''">
            <div class="login-btn" @click="toLogin">注册 / 登录</div>
          </div>
          <div class="nav-item" v-if="accessToken && accessToken != ''">
            <div @click="tohome">个人中心</div>
          </div>
        </div>
        <div class="phone-bar">
          <i class="el-icon-s-unfold" style="font-size:28px;color:rgba(37, 85, 156, 1)" @click="showSelect=true"></i>
        </div>
      </div>
      <div class="collapse-navigate" v-if="showSelect">
        <div @click="toShop()">藏品</div>
        <div @click="toType()">专辑</div>
        <div @click="tonewsList">资讯</div>
        <div @click="register">发布</div>
        <div @click="todrawer">认证</div>
        <div @click="toHelp">帮助</div>
        <div v-if="!accessToken || accessToken == ''" @click="toLogin">注册 / 登录</div>
        <div @click="tohome" v-if="accessToken && accessToken != ''">个人中心</div>
      </div>
    </div>
    <!-- <div class="header">
      <div class="content">
        <div style="display:flex;">
          <a href="">
            <img src="@/assets/logo.png" alt="">
          </a>
          <el-input v-model="input" suffix-icon="el-icon-zoom-in" placeholder="请输入搜索的数字藏品" style="width:320px;border-radius:0;margin-left:80px"></el-input>
        </div>
        <div class="navBar" style="display:flex;align-items:center">
          <div class="nav-item" @mouseover="mouseOverShoping" @mouseleave="mouseLeaveShoping">
            <div>类型</div>
            <ul class="sub-nav" v-if="typeShoping">
              <li @click="toShop(1)">
                <img src="@/assets/art.png" alt="">
                艺术品
              </li>
              <li @click="toShop(2)">
                <img src="@/assets/xiaoshuo.png" alt="">
                收藏品
              </li>
            </ul>
          </div>
          <div class="nav-item" @mouseover="mouseOver" @mouseleave="mouseLeave">
            <div>专辑</div>
            <ul class="sub-nav" v-if="type">
              <li @click="toType(1, '游戏')">
                <img src="@/assets/youxi.png" alt="">
                游戏
              </li>
              <li @click="toType(2, '音乐')">
                <img src="@/assets/music.png" alt="">
                音乐
              </li>
              <li @click="toType(3, '艺术品')">
                <img src="@/assets/art.png" alt="">
                艺术品
              </li>
            </ul>
          </div>
          <div class="nav-item" @mouseover="mouseOverRank" @mouseleave="mouseLeaveRank">
            <div>数据</div>
            <ul class="sub-nav" v-if="rank">
              <li @click="toRankList">排名</li>
              <li @click="tonewsList">新闻资讯</li>
              <li @click="actived">交易记录</li>
            </ul>
            <div @click="tonewsList">资讯</div>
          </div>
          <div class="nav-item">
            <div @click="register">发布数字藏品</div>
          </div>
          <div class="nav-item">
            <div @click="todrawer">认证</div>
            <div style="line-height:10px">
              <img @click="todrawer" src="@/assets/renzheng.png" alt="" style="width:30px" title="认证中心">
            </div>
          </div>
          <div class="nav-item" @mouseover="mouseOverShop" @mouseleave="mouseLeaveShop">
            <div style="line-height:85px;height:72px">
              <img src="@/assets/person.png" alt="" style="width:30px" title="个人中心">
            </div>
            <ul class="sub-nav" v-if="shop">
              <li @click="tohome">
                个人中心
              </li>
              <li @click="register">
                发布数字藏品
              </li>
            </ul>
          </div>
          <div class="nav-item">
            <div style="line-height:10px">
              <img @click="tohome" src="@/assets/person.png" alt="" style="width:30px" title="个人中心">
            </div>
          </div>
          <div class="nav-item">
            <div>帮助文档</div>
            <div style="line-height:10px" @click="toHelp">
              <img src="@/assets/help.png" alt="" style="width:30px;margin-top:2px" title="帮助中心">
            </div>
          </div>
          <div class="nav-item" v-if="tk != ''">
            <div>帮助文档</div>
            <div style="line-height:10px">
              <img src="@/assets/out.png" alt="" style="width:22px;margin-top:2px;" title="退出登录" @click="loginout">
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="empty-box"></div>
  </div>
</template>

<script>
import radio from '@/components/radio'
import auth from '@/components/authentication'
import { signOut, getCodeBase } from '../api/index';
import { mapGetters } from 'vuex'
export default {
  name: 'HelloWorld',
  components: {
    radio,
    auth
  },
  data() {
    return {
      searchVal: '',
      showSelect: false,
      tk: '',
      showStatus: false,
      input: '',
      type: false,
      rank: false,
      shop: false,
      drawer: false,
      typeShoping: false,
      verify: ''
    }
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  mounted() {
    if(this.accessToken && this.accessToken != '') {
      this.tk = this.accessToken
    }
  },
  methods: {
    toSearch() {
      this.$router.push('./search?searchVal=' + this.searchVal)
      this.$parent.getCollect()
      this.$parent.getAlbum()
    },
    getMsgFormSon(data){
      this.tk = data
    },
    loginout() {
      this.$confirm('确定退出当前账户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        signOut().then(res=> {
          if(res.code === 0 ){
            this.$store.dispatch('accessToken','')
            localStorage.removeItem('verify')
            this.$store.dispatch('userCode','')
            this.$router.push('/')
            this.tk = ''
          } else {
            this.$message.error(res.message)
          }
        })
        // this.$store.dispatch('LogOut').then(() => {
        //   location.reload()
        // })
      })
    },
    onSubmit(){
      // this.$router.push('./search')
    },
    toHelp() {
      this.showSelect = false
      this.$router.push('./helpCenter')
    },
    toLogin() {
      this.showSelect = false
      this.showStatus = true
    },
    todrawer() {
      this.showSelect = false
      if (this.accessToken && this.accessToken != '') {
        let data={
          userCode: this.userCode
        }
        getCodeBase(data).then(res => {
          if (res.code == 0) {
            this.verify = res.data.idVerifyType
            localStorage.setItem('verify', res.data.idVerifyType)
            this.drawer = true
          }else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.showStatus = true
      }
    },
    toType() {
      this.showSelect = false
      if (this.accessToken && this.accessToken != '') {
        this.$router.push('./collection')
      } else {
        this.showStatus = true
      }
    },
    mouseOverShop() {
      this.shop = true
    },
    mouseLeaveShop() {
      this.shop = false
    },
    mouseOverShoping() {
      this.typeShoping = true
    },
    mouseLeaveShoping() {
      this.typeShoping = false
    },
    mouseOver() {
      this.type = true
    },
    mouseLeave() {
      this.type = false
    },
    mouseOverRank() {
      this.rank = true
    },
    mouseLeaveRank() {
      this.rank = false
    },
    tohome() {
      this.showSelect = false
      if (this.accessToken && this.accessToken != '') {
        this.$router.push('./radicalDreamer')
      } else {
        this.showStatus = true
      }
    },
    tonewsList() {
      this.showSelect = false
      if (this.accessToken && this.accessToken != '') {
        this.$router.push('./news')
      } else {
        this.showStatus = true
      }
    },
    toRankList() {
      this.$router.push('./rank')
    },
    actived() {
      this.$router.push('./actived')
    },
    register() {
      const verity = localStorage.getItem('verify')
      this.showSelect = false
      // this.showStatus = true
      if (this.accessToken && this.accessToken != '') {
        if (verity!=0) {
          this.$router.push('./created')
        } else if(verity==0){
          this.drawer = true
        }
      } else if (!this.accessToken || this.accessToken == ''){
        this.showStatus = true
      }
    },
    toShop() {
      this.showSelect = false
      if (this.accessToken && this.accessToken != '') {
        this.$router.push('./shop')
      } else {
        this.showStatus = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.empty-box{
  width: 100%;
  background: #fff;
  height: 72px;
}
.header {
  width: 100%;
  background: #fff;
  height: 72px;
  box-shadow: rgb(4 27 29 / 15%) 0px 0px 10px 0px;
  position: fixed;
  z-index: 1000;
  background: url('../assets/nav-bac.jpg');
  background-size:100% 100%;
  -moz-background-size:100% 100%; /* 老版本的 Firefox */
  background-repeat:no-repeat;
}
.content {
  max-width: 1180px;
  margin: auto;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 72px;
  align-items: center;
}
.nav-item {
    cursor: pointer;
    float: left;
    text-align: left;
    position: relative;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 32, 0, 1);
    margin-left: 20px;
}
.search-input {
  /* box-shadow: 1px 1px 3px rgba(11, 151, 253, 0.25); */
  border-radius: 8px;
  width:280px;
  margin-left:80px;
}
.nav-item {
    cursor: pointer;
    float: left;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    padding: 0 10px;
    font-weight: 600;
    font-size: 16px;
    color: rgba(4, 17, 29, 0.75);
}
.login-btn {
  width: 109px;
  height: 40px;
  opacity: 1;
  border-radius: 8px;
  background: rgba(2, 32, 78, 1);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 40px !important;
}
.sub-nav li {
  cursor: pointer;
    float: left;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    padding: 0 10px;
    font-weight: 600;
    font-size: 14px;
    color: rgba(4, 17, 29, 0.75);
}
.nav-item div {
  line-height: 72px;
}
.sub-nav {
    float: left;
    border-radius: 6px;
    z-index: 999;
    position: absolute;
    margin: 0 10px;
    left: -12%;
    box-shadow: rgb(4 17 29 / 25%) 0px 6px 10px 0px;
    top: 100%;
}
.sub-nav li {
  width: 134px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.sub-nav li:hover{
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}
.sub-nav li img {
  width: 30px;
  height: 30px;
}
.header-container .navBar .nav-item .sub-nav li:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.auth {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.auth div {
  position: relative;
  text-align: center;
  line-height: 60px;
  border: 1px solid #eee;
  margin: 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.auth div p {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  color: #fff;
  line-height: 10px !important;
  padding: 3px 4px;
  background: rgb(204, 204, 204);
  border-radius: 4px;
}
.auth div:hover {
  border: 1px solid rgb(32, 129, 226);
  color: rgb(32, 129, 226);
}
.upload-type {
  color: rgb(53, 56, 64);
  font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
}
.upload-type span {
  color: red;
}
</style>
<style>
.header .el-input__inner {
    border-radius: 10px;
}
@media screen and (max-width: 900px) {
  .header .el-input {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .content a img {
    /* max-width: 100px; */
  }
}
@media screen and (max-width: 1100px){
  .search-input {
    width: 203px !important;
    margin-left: 40px !important;
  }
}
</style>
<template>
  <div>
    <helloWorld />
    <div class="contain">
      <p class="searchTip">符合 <span>{{searchVal}}</span> 搜索如下：</p>
      <div class="tab-search">
        <el-tabs v-model="activeName">
          <el-tab-pane label="商品" name="first">
            <div>
              <div class="works" v-if="collectTotal > 0">
                <div class="works-item" v-for="(item, index) in collectList" :key="index" @click="toDetail(item)">
                  <div class="img-box">
                    <img class="collection-picture" :src="item.pic">
                  </div>
                  <div style="padding:0 14px">
                    <p class="works-name" style="font-weight:700;margin-top:6px">{{item.colName}}</p>
                    <p class="creator" style="margin-top:6px">{{item.creator.nickname}}</p>
                    <div class="price" style="margin-top:6px">
                      <p style="color:rgba(2, 32, 78, 1)">￥{{item.price}}</p>
                    </div>
                    <!-- <div class="likenum">
                      <img src="@/assets/give.png" alt="">
                      <a>10</a>
                    </div> -->
                  </div>
                </div>
              </div>
              <div v-else>
                  <el-empty description="暂无符合条件的商品" style="margin-top:30px"></el-empty>
              </div>
              <pagination
                v-show="collectTotal>0"
                :total="collectTotal"
                :page-sizes="[12, 24, 36, 48, 50]"
                :page.sync="queryParams.PageNum"
                :limit.sync="queryParams.PageSize"
                @pagination="getCollect"
              />
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="艺术家" name="second">
            <div class="artor">
              <div class="contentWrap" v-for="item in 4" :key="item" @click="toMe">
                <div class="actor-img">
                  <img src="@/assets/avatar.jpeg" alt="">
                  <p>张三</p>
                </div>
                <div class="brief">
                  <div>
                    <img src="@/assets/jianli.png" alt="">
                    <span>个人简介</span>
                  </div>
                  <p>元宇宙AI艺术家
洛可可创新设计集团创始人、畅销书《产品三观》作者、中国元宇宙力美学第一人，中国唯一独揽红点、iF、IDEA、G-Mark、红星等国际顶级设计金奖的设计师，兼具商业头脑和设计才华的商业设计师第一人。
首度开创式提出元宇宙力美学，结合元宇宙概念，运用数字孪生，AR,VR等技术，创造如花元宇宙艺术世界，创作了《如花在野》一系列绘画作品、陶瓷作品、雕塑作品，数字AI作品。
</p>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
          <el-tab-pane label="专辑" name="third">
            <div class="fZZKFU" v-if="albumTotal > 0">
              <div class="fZZKFU-item" v-for="(item, ind) in albumList" :key="ind" @click="toAuthor(item)">
                <img class="fZZKFU-bac" :src="item.coverPic" alt="">
                <div style="text-align:center">
                  <img v-if="item.creator.avatar!=''" class="fZZKFU-avatar" :src="item.avatar" alt="">
                  <img  class="fZZKFU-avatar" v-else src="@/assets/avatar.jpg" alt="">
                </div>
                <p class="fZZKFU-name">{{item.title}}</p>
                <p class="from">来自<span>{{item.creator.nickname}}</span></p>
                <p class="fZZKFU-desc">{{item.desc}}</p>
              </div>
            </div>
            <div v-else>
                  <el-empty description="暂无符合条件的专辑" style="margin-top:30px"></el-empty>
              </div>
            <pagination
                v-show="albumTotal>0"
                :total="albumTotal"
                :page-sizes="[12, 24, 36, 48, 50]"
                :page.sync="queryParamsAlbum.PageNum"
                :limit.sync="queryParamsAlbum.PageSize"
                @pagination="getAlbum"
              />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import {getCollectList, getAlbum, downImg} from '../../api/index'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    this.searchVal = this.$route.query.searchVal
    this.getCollect()
    this.getAlbum()
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  data() {
    return {
      queryParams: {
        PageNum: 1,
        PageSize: 12,
        colName: ''
      },
      env: process.env.VUE_APP_BASE_API,
      activeName: 'first',
      searchVal: '',
      collectList: [],
      collectTotal: 0,
      albumList: [],
      queryParamsAlbum: {
        PageNum: 1,
        PageSize: 12,
        title: ''
      },
      albumTotal: 0
    }
  },
  methods: {
    getCollect() {
      this.searchVal = this.$route.query.searchVal
      this.queryParams.colName = this.searchVal
      getCollectList(this.queryParams).then(res => {
        if (res.code ==0) {
          this.collectList = res.data.list
          this.collectTotal = res.data.total
          if (this.collectList == null ) {
            return
          }
          for (var s = 0; s < this.collectList.length; s++) {
            if (this.collectList[s].saleMode != 0 ){
              this.collectList[s].pic = this.env+this.collectList[s].pic
            }else {
              this.getCollcetPic(s, this.collectList[s].pic)
            }
            if (this.collectList[s].creator.avatar != '') {
              this.collectList[s].avatar = this.env + this.collectList[s].creator.avatar
            }
          }
        }
      })
    },
    getCollcetPic(i, pic) {
      let a = {
        path: pic
      }
      downImg(a).then(res => {
        let blob = new Blob([res]);   // 返回的文件流数据
        let url = window.URL.createObjectURL(blob);  // 将他转化为路径
        this.collectList[i].pic = url;
      })
    },
    getAlbum() {
      this.searchVal = this.$route.query.searchVal
      this.queryParamsAlbum.title = this.searchVal
      getAlbum(this.queryParamsAlbum).then(res => {
        if (res.code ==0) {
          this.albumList = res.data.list
          this.albumTotal = res.data.total
          for (var s = 0; s < this.albumList.length; s++) {
            this.albumList[s].coverPic = this.env + this.albumList[s].coverPic
            if (this.albumList[s].creator.avatar != '') {
              this.albumList[s].avatar = this.env + this.albumList[s].creator.avatar
            }
          }
        }
      })
    },
    toDetail(item) {
      this.$router.push('./detail?colCode='+item.colCode)
    },
    toAuthor(item) {
      this.$router.push('./author?title=' + item.title + '&albumId=' + item.id)
    },
    toMe() {
      this.$router.push('./radicalDreamer')
    }
  }
}
</script>
<style scoped>
.img-box {
  width: 100%;
  max-height: 250px;
  text-align: center;
}
.collection-picture {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 230px;
}
.brief {
  margin-left: 20px;
}
.brief div{
  display: flex;
  align-items: center;
}
.brief div img {
  width: 15px;
  height: 16px;
}
.brief div span{
  font-size: 16px;
    font-family: PingFangSC,PingFangSC-Semibold;
    font-weight: 600;
    color: #333;
    margin-left: 2px;
}
.brief p {
  height: 145px;
    overflow-y: auto;
    margin-top: 17px;
    font-size: 14px;
    color: #333;
}
.actor-img img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.actor-img{
  text-align: center;
}
.actor-img p{
  font-size: 18px;
    font-weight: 600;
    color: #333;
  margin-top:10px;

}
.artor .contentWrap {
  background: #fff;
  box-shadow: 0 0 20px 0 #e8e8e8;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin: 20px;
  cursor: pointer;
}
.artor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.contain {
  max-width: 1180px;
  margin: auto;
  padding: 50px 0 30px 0;
}
.searchTip p{
  font-size: 14px;
    color: #333;
}
.searchTip span {
  color: #1f6cc7;
}
.tab-search {
  margin-top:30px
}
.works {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1180px;
  margin: auto;
  /* margin-top: 20px; */
}
.works-item {
  margin: 10px 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.works-item img {
  width: 100%;
}
.works-item:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  margin-top: 6px;
}
.works-name {
  font-size: 18px;
  margin-top:4px;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1; /*超出几行后省略号*/
overflow: hidden;
}
.creator {
  font-size: 14px;
  color: #333;
  margin-top:4px;
}
.price {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.price p {
  color: #999;
}
.price p span {
  color: #333;
}
.likenum {
  padding: 8px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.likenum a {
  font-size: 14px;
  color: #999;
  /* margin-left: 4px; */
}
.likenum img {
  width: 26px;
}
.fZZKFU {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1180px;
  margin: auto;
  /* margin-top: 20px; */
}
.fZZKFU-item {
  margin: 10px 20px;
  padding-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
}
.fZZKFU-bac {
  width: 100%;
  cursor: pointer;
  height: 200px;
  object-fit: cover;
}
.fZZKFU-item:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  margin-top: 6px;
}
.fZZKFU-avatar {
  width: 60px;
  border-radius: 50%;
  margin-top: -32px;
  cursor: pointer;
}
.fZZKFU-name {
  text-align: center;
  font-size: 14px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  width: 80%;
  margin: auto;
  cursor: pointer;
}
.from {
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  color: #999;
  margin-top: 6px;
}
.from span {
  color: rgb(32, 129, 226);
  margin-left: 4px;
  cursor: pointer;
}
.fZZKFU-desc {
  font-size: 12px;
  line-height: 22px;
  color: #999;
  width: 80%;
  margin: auto;
  word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
margin-top: 6px;
cursor: pointer;
}
@media screen and (max-width: 900px) {
  .artor {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 720px){
  .fZZKFU{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .works{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 720px) and (max-width: 900px){
  .fZZKFU{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .works{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>